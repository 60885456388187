export const imports = {
  'docs/Currency.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-currency" */ 'docs/Currency.mdx'
    ),
  'docs/Date.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-date" */ 'docs/Date.mdx'
    ),
  'docs/Home.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-home" */ 'docs/Home.mdx'
    ),
}
